// 第三方模块
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
// 内部模块
import { StoreContext } from '@src/store';
// 资源
import { Tabbar } from '../Tabbar';
// 样式
import './index.less';

export const WithTabbar = observer(() => {
    const { system } = useContext(StoreContext);

    return (
        <div className='component-with-tabbar'>
            <div className='part-outlet'>
                <Outlet />
            </div>
            <div className='part-tabbar'>
                <Tabbar config={system?.config?.iconconfigs} noticeTitle={system?.config?.banner_tip} />
            </div>
        </div>
    );
});
