/* eslint-disable jsx-a11y/control-has-associated-label */
import { FloatingBubble } from 'antd-mobile';
import { PhoneFill } from 'antd-mobile-icons';
import { useLocation } from 'react-router-dom';

type PropsType = {
    phoneNumber: string;
};

const PhoneBubble = (props: PropsType) => {
    const { phoneNumber } = props;
    const { pathname } = useLocation();

    if (pathname === '/tv') {
        return null;
    }

    return (
        <FloatingBubble
            axis='xy'
            magnetic='x'
            style={{
                '--initial-position-bottom': '110px',
                '--initial-position-right': '14px',
                '--edge-distance': '14px',
                '--background': '#2d69ff',
                position: 'relative'
            }}
        >
            <PhoneFill fontSize={26} />
            <a
                style={{ display: 'block', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, opacity: 0 }}
                href={`tel:${phoneNumber}`}
            >
                {phoneNumber}
            </a>
        </FloatingBubble>
    );
};

export default PhoneBubble;
