// 第三方模块
import { FloatingBubble } from 'antd-mobile';
import { observer } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// 外部模块
import { StoreContext } from '@src/store';
// import { PhoneFill } from 'antd-mobile-icons';
import IconHome from './imgs/icon-home.png';

const RelaunchBubble = observer(() => {
    const { key, pathname } = useLocation();
    const { system } = useContext(StoreContext);
    const homePath = useMemo(() => {
        return system?.config?.iconconfigs?.[0]?.link;
    }, [system]);
    const navigate = useNavigate();

    const relaunch = () => {
        if (homePath) navigate(homePath, { replace: true });
    };

    if (key !== 'default' || pathname === homePath || pathname === '/tv') {
        return null;
    }

    return (
        <FloatingBubble
            axis='xy'
            magnetic='x'
            style={{
                '--initial-position-bottom': '170px',
                '--initial-position-right': '14px',
                '--edge-distance': '14px',
                '--background': '#2d69ff',
                position: 'relative'
            }}
            onClick={relaunch}
        >
            <img style={{ width: '25px', height: '25px' }} src={IconHome} alt='回到首页' />
        </FloatingBubble>
    );
});

export default RelaunchBubble;
