import store from 'store';

class Cache {
    getToken = (): string => {
        return store.get('__TOKEN_CC_WEB__');
    };

    setToken = (token: string) => {
        store.set('__TOKEN_CC_WEB__', token);
    };

    removeToken = () => {
        store.remove('__TOKEN_CC_WEB__');
    };

    getMyFavorite = (): string => {
        return store.get('__TREND_MY_FAVORITE__');
    };

    setMyFavorite = (id: string | number) => {
        const value = this.getMyFavorite();
        store.set('__TREND_MY_FAVORITE__', value ? `${value},${id}` : `${id}`);
    };

    removeMyFavorite = (ids: string) => {
        store.set('__TREND_MY_FAVORITE__', ids);
    };

    removeAllMyFavorite = () => {
        store.remove('__TREND_MY_FAVORITE__');
    };
}

export default new Cache();
