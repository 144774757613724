import cache from '@src/internal/cache';
import { observable } from 'mobx';
import { Store } from '.';

class User {
    @observable token = cache.getToken();

    @observable rootStore: Store = null;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;
    }

    setToken(token: string) {
        cache.setToken(token);
        this.token = token;
    }

    removeToken() {
        cache.removeToken();
        this.token = '';
    }
}

export default User;
