// 基础模块
import { useLocation, useNavigate } from 'react-router-dom';
import { NoticeBar, SafeArea } from 'antd-mobile';
import qs from 'qs';
// 内部模块
import { SystemConfigDto } from '@src/types/dto';
// 样式
import './index.less';

interface PropsType {
    config: SystemConfigDto['iconconfigs'];
    noticeTitle?: string;
}

Tabbar.defaultProps = {
    noticeTitle: ''
};

export function Tabbar(props: PropsType) {
    const { config, noticeTitle } = props;
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const onSwitch = (tabbarItem: PropsType['config'][number]) => {
        switch (tabbarItem.type) {
            case 'iframe': {
                navigate(`/web?url=${encodeURIComponent(tabbarItem.link)}`, { replace: true });
                break;
            }

            case 'system': {
                navigate(tabbarItem.link, { replace: true });
                break;
            }

            case 'redirect': {
                window.open(tabbarItem.link);
                break;
            }

            // no default
        }
    };

    return (
        <div className='component-tabbar' style={{ backgroundColor: '#000' }}>
            <div className='module-notice'>
                {noticeTitle && (
                    <NoticeBar
                        content={noticeTitle}
                        color='default'
                        style={{
                            '--background-color': '#daa520',
                            '--height': '8vw',
                            '--border-color': 'transparent',
                            '--font-size': '3.6vw',
                            '--icon-font-size': '4vw'
                        }}
                    />
                )}
            </div>
            <div className='module-tabbar'>
                {config.map(item => {
                    const { url } = qs.parse(search.slice(1)) || {};
                    const isMatch = item.type === 'system' ? pathname === item.link : pathname === '/web' && url === item.link;

                    return (
                        <div className='tabbar-item' key={item.Id} role='button' onClick={() => onSwitch(item)}>
                            <div className='icon'>
                                {isMatch ? (
                                    <img className='img-icon' src={item?.select_icon} alt='' />
                                ) : (
                                    <img className='img-icon' src={item?.icon} alt='' />
                                )}
                            </div>

                            <div className='text' style={{ color: isMatch ? item.selected_color : item.color }}>
                                {item?.name ?? ''}
                            </div>
                        </div>
                    );
                })}
            </div>
            <SafeArea position='bottom' />
        </div>
    );
}
