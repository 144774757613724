// 第三方模块
import { lazy, Suspense, useContext, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useMount } from 'ahooks';
import { ErrorBlock, Button } from 'antd-mobile';
import isMobile from 'ismobilejs';
// 内部模块
import { WithTabbar } from '@src/app/components/WithTabbar';
import PhoneBubble from '@src/app/components/PhoneBubble';
import RelaunchBubble from '@src/app/components/RelaunchBubble';

const TrendChart = lazy(() => import('@src/app/mobile/TrendChart'));
const IframeWeb = lazy(() => import('@src/app/mobile/Iframe'));
const Account = lazy(() => import('@src/app/mobile/Account'));
const Reminder = lazy(() => import('@src/app/mobile/Reminder'));
const Home = lazy(() => import('@src/app/mobile/Home'));
const Favorite = lazy(() => import('@src/app/mobile/Favorite'));
const PcHome = lazy(() => import('@src/app/pc/Home'));
const TvHome = lazy(() => import('@src/app/tv/Home'));

import { getSystemConfig } from '@src/apis';
import { StoreContext } from '@src/store';

function NoMatch() {
    const navigate = useNavigate();
    const navToHome = () => navigate('/');
    return (
        <ErrorBlock fullPage status='empty' title='' description='页面不存在(404)'>
            <Button color='primary' size='middle' onClick={navToHome}>
                返回首页
            </Button>
        </ErrorBlock>
    );
}

const App = () => {
    const { system } = useContext(StoreContext);
    const [isReady, setPageStatus] = useState(false);
    const { any: isMobileNavigator } = isMobile(window.navigator);

    const systemStatus = useMemo(() => Boolean(system.config), [system.config]);
    const phoneNumber = useMemo(() => system.config?.site_mobile || system.config?.site_phone, [system.config]);

    const getSystemConfigByAPI = () => {
        return getSystemConfig()
            .then(res => {
                system.setConfig(res.data);
            })
            .finally(() => {
                setPageStatus(true);
            });
    };

    useMount(() => {
        getSystemConfigByAPI();
    });

    if (!isMobileNavigator) {
        return (
            <>
                {isReady && systemStatus && (
                    <Suspense fallback={<div />}>
                        <Routes>
                            <Route path='/'>
                                <Route index element={<PcHome />} />
                                <Route path='/tv' element={<TvHome />} />
                                <Route path='*' element={<Navigate to='/' replace />} />
                            </Route>
                        </Routes>
                    </Suspense>
                )}
                {isReady && !systemStatus && (
                    <ErrorBlock fullPage status='busy' title='' description='服务器异常, 请稍后重试'>
                        <Button color='primary' size='middle' onClick={getSystemConfigByAPI}>
                            点击重试
                        </Button>
                    </ErrorBlock>
                )}
            </>
        );
    }

    return (
        <>
            {isReady && systemStatus && (
                <>
                    <Suspense fallback={<div />}>
                        <Routes>
                            <Route path='/' element={<WithTabbar />}>
                                <Route index element={<Home />} />
                                <Route path='/account' element={<Account />} />
                                <Route path='/favorite' element={<Favorite />} />
                                <Route path='/reminder' element={<Reminder />} />
                                <Route path='/web' element={<IframeWeb />} />
                            </Route>
                            <Route path='/tv' element={<TvHome />} />
                            <Route path='/chart' element={<TrendChart />} />
                            <Route path='*' element={<NoMatch />} />
                        </Routes>
                    </Suspense>
                    <PhoneBubble phoneNumber={phoneNumber} />
                    <RelaunchBubble />
                </>
            )}
            {isReady && !systemStatus && (
                <ErrorBlock fullPage status='busy' title='' description='服务器异常, 请稍后重试'>
                    <Button color='primary' size='middle' onClick={getSystemConfigByAPI}>
                        点击重试
                    </Button>
                </ErrorBlock>
            )}
        </>
    );
};

export default observer(App);
