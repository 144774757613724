// 外部模块
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dialog } from 'antd-mobile';
import qs from 'qs';
// 内部模块
import { rootStoreValue } from '@src/store';
import cache from '@src/internal/cache';

export interface ApiResult<T> {
    code: number;
    data: T;
}

export type RequestConfig = AxiosRequestConfig;

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

const interceptors = {
    requestSuccess: config => {
        const token = cache.getToken();
        if (token) config.headers.UserToken = token;
        config.url = `${BASE_API_URL}${config.url}`;
        config.data = qs.stringify(config.data);
        config.headers.HEADER_CLIENT = 'Web';
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        return config;
    },

    requestError: error => {
        return Promise.reject(error);
    },

    responseSuccess: res => {
        const { code, msg } = res.data;
        switch (code) {
            case -1: {
                Dialog.alert({
                    content: msg || '抱歉，请求异常'
                });
                return Promise.reject(res);
            }
            case 200: {
                return Promise.resolve(res);
            }

            case 401: {
                rootStoreValue.user.removeToken();
                return Promise.reject(res);
            }

            case 500: {
                Dialog.alert({
                    content: msg || '抱歉，系统异常～'
                });
                return Promise.reject(res);
            }
        }
        return res;
    },

    responseError: error => {
        const { response } = error;
        if (response) {
            const { status, data } = response;
            switch (status) {
                case 403:
                    Dialog.alert({
                        content: data.msg || '对不起，您没有访问该资源的权限'
                    });
                    break;
                case 404:
                    Dialog.alert({
                        content: data.msg || '抱歉，页面不见了～'
                    });
                    break;
                case 500:
                    Dialog.alert({
                        content: data.msg || '抱歉，系统异常～'
                    });
                    break;
                default:
                    Dialog.alert({
                        content: data.msg || '抱歉，请求失败'
                    });
            }
        }
        return Promise.reject(error);
    }
};

class Request {
    public instance: AxiosInstance;

    constructor(config: RequestConfig) {
        this.instance = axios.create(config);
        this.instance.interceptors.request.use(interceptors.requestSuccess, interceptors.requestError);
        this.instance.interceptors.response.use(interceptors.responseSuccess, interceptors.responseError);
    }

    // 传入的泛型是约束返回值
    request<T>(config: RequestConfig): Promise<ApiResult<T>> {
        return new Promise((resolve, reject) => {
            this.instance
                .request<any, AxiosResponse>(config)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        });
    }

    get<T>(url: string, params?: any, config?: RequestConfig): Promise<ApiResult<T>> {
        return this.request<T>({ ...config, url, params, method: 'GET' });
    }

    delete<T>(url: string, data?: any, config?: RequestConfig): Promise<ApiResult<T>> {
        return this.request<T>({ ...config, url, data, method: 'DELETE' });
    }

    post<T>(url: string, data?: any, config?: RequestConfig): Promise<ApiResult<T>> {
        return this.request<T>({ ...config, url, data, method: 'POST' });
    }

    put<T>(url: string, data?: any, config?: RequestConfig): Promise<ApiResult<T>> {
        return this.request<T>({ ...config, url, data, method: 'PUT' });
    }
}

const request = new Request({
    timeout: 20000
});

export default request;
