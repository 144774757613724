import { SystemConfigDto } from '@src/types/dto';
import request from './request';

/**
 * 获取系统的配置数据
 */
export const getSystemConfig = () => {
    return request.get<SystemConfigDto>('/systemconfigs');
};

/**
 * 获取首页趋势的列表数据
 */
export type TrendTableDataType = {
    Id: number;
    order: number;
    ask: number;
    bid: number;
    close: number;
    code: string;
    high: number;
    low: number;
    open: number;
    sname: string;
    type: string;
    updn_pct: number;
    updown: number;
    yd_close: number;
    point: number;
};
export const getTrendTableData = () => {
    return request.get<TrendTableDataType[]>('/index_list');
};

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
    return request.get<any>('/base_info');
};

/**
 * 获取用户的具体账户信息
 * @returns
 */
export type UserAccountListInfoResponse = { Id: number; name: string; url: string }[];
export const getUserAccountListInfo = () => {
    return request.get<UserAccountListInfoResponse>('/info_list');
};

export const getInNosettleOrder = () => {
    return request.get<any>('/in_nosettle_order');
};

export const getDeliveryOrderInfo = () => {
    return request.get<any>('/delivery_order_info');
};

/**
 * 添加价格通知
 */
type AddPriceNotifyParams = { type: string; price: string; code: string };
export const addPriceNotify = (opt: AddPriceNotifyParams) => {
    return request.post<any>('/set_notify', { ...opt, flag: 1 });
};

/**
 * 移除价格通知
 */
export const removePriceNotify = (opt: AddPriceNotifyParams) => {
    return request.post<any>('/set_notify', { ...opt, flag: 0 });
};

/**
 * 获取到货提醒列表
 */
export type NotifyListItemResponse = {
    Id: number;
    code: string;
    name: string;
    phone: string;
    price: number;
    type: string;
};
export const getNotifyList = () => {
    return request.get<NotifyListItemResponse[]>('/notify_list');
};

/**
 * 获取用户的验证码
 */
type GetLoginSmsParams = { mobile: string };
export const getLoginSms = (opt: GetLoginSmsParams) => {
    return request.post<unknown>('/send_sms', opt);
};

/**
 * 用户登录
 */
type GetUserLoginParams = { mobile: string; code: string };
export const getUserLogin = (opt: GetUserLoginParams) => {
    return request.post<{ token: string }>('/login', opt);
};

type GetKChartDataParams = { code: string; type: string; range: string };
export type KChartDateItemType = {
    close: number;
    date: string;
    highest: number;
    lowest: number;
    open: number;
};
export const getKChartData = (opt: GetKChartDataParams) => {
    return request.get<KChartDateItemType[]>('/history', opt);
};
