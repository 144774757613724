import { SystemConfigDto } from '@src/types/dto';
import { observable } from 'mobx';
import { Store } from '.';

class System {
    @observable config: SystemConfigDto = null;

    @observable rootStore: Store = null;

    constructor(rootStore: Store) {
        this.rootStore = rootStore;
    }

    setConfig(config?: SystemConfigDto) {
        this.config = config;
    }
}

export default System;
