import { createContext } from 'react';
import User from './user';
import System from './system';

export class Store {
    user: User;

    system: System;

    constructor() {
        this.user = new User(this);
        this.system = new System(this);
    }
}

export const rootStoreValue = new Store();

export const StoreContext = createContext<Store>(undefined);
