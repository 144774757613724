// polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// 引入默认样式
import 'normalize.css';
import './index.less';
// 第三方模块
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// 内部模块
import App from '@src/app';
import { StoreContext, rootStoreValue } from '@src/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <StoreContext.Provider value={rootStoreValue}>
            <App />
        </StoreContext.Provider>
    </BrowserRouter>
);
